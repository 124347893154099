import { useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/20/solid'

const frequencies = [
  { value: 'monthly', label: 'Monthly', priceSuffix: '/month' },
  // { value: 'annually', label: 'Annually', priceSuffix: '/year' },
]

const tiers = [
  {
    name: 'Sunin Referral Partner',
    id: 'tier-freelancer',
    href: '/signup',
    price: { monthly: 'Free', annually: 'Free' },
    description: "Earn rewards by providing lead information and scheduling appointments—no sales work required.",
    features: ['Earn up to 20% of the profit (minimum payout of $2,500)', 'Complete lead management platform', 'Basic analytics'],
    featured: false,
    cta: 'Get Started',
  },
  {
    name: 'Sunin Sales Partner',
    id: 'tier-startup',
    href: '/signup',
    price: { monthly: '$129', annually: '$1399' },
    description: 'Scale your earnings by actively engaging and closing sales!',
    features: [
      'Earn up to 60 percent of the profit',
      'Complete project management platform',
      'Advanced analytics',
      'Access to Sunin Proposals',
      'Access to Sunin Financing Options',
    ],
    featured: false,
    cta: 'Get Started',
  },
  {
    name: 'Sunin Enterprise Partner',
    id: 'tier-enterprise',
    // href: '#',
    price: 'Custom',
    description: 'Tailored solutions for large-scale operations and team incentives.',
    features: [
      'Team members earn up to 70 percent of the profit',
      'Earn volume-based team bonuses',
      'Complete project management platform',
      'Access to Sunin Teams',
      'Advanced analytics',
      'Access to Sunin Proposals',
      'Access to Sunin Financing Options',
    ],
    featured: true,
    cta: 'Coming Soon',
  },
];


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Example() {
  const [frequency, setFrequency] = useState(frequencies[0])

  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <h2 className="text-base font-semibold leading-7 text-blue-600">Pricing</h2>
          <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Pricing plans for every level.
          </p>
        </div>
        <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
          Explore our tiered partnership programs tailored to match your expertise and 
          engagement level in the renewable energy sector. Whether starting as a referral 
          partner or scaling operations as an enterprise, our self-serve platform equips 
          you with the tools and support needed to succeed. The SUNIN platform is designed 
          to grow with you, ensuring opportunities for advancement and increased earnings 
          as you expand your capabilities.
        </p>
      
        
        <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {tiers.map((tier) => (
            <div
              key={tier.id}
              className={classNames(
                tier.featured ? 'bg-gray-900 ring-gray-900' : 'ring-gray-200',
                'rounded-3xl p-8 ring-1 xl:p-10'
              )}
            >
              <h3
                id={tier.id}
                className={classNames(
                  tier.featured ? 'text-white' : 'text-gray-900',
                  'text-lg font-semibold leading-8'
                )}
              >
                {tier.name}
              </h3>
              <p className={classNames(tier.featured ? 'text-gray-300' : 'text-gray-600', 'mt-4 text-sm leading-6')}>
                {tier.description}
              </p>
              <p className="mt-6 flex items-baseline gap-x-1">
                <span
                  className={classNames(
                    tier.featured ? 'text-white' : 'text-gray-900',
                    'text-4xl font-bold tracking-tight'
                  )}
                >
                  {typeof tier.price === 'string' ? tier.price : tier.price[frequency.value]}
                </span>
                {typeof tier.price !== 'string' ? (
                  <span
                    className={classNames(
                      tier.featured ? 'text-gray-300' : 'text-gray-600',
                      'text-sm font-semibold leading-6'
                    )}
                  >
                    {frequency.priceSuffix}
                  </span>
                ) : null}
              </p>
              <a
                href={tier.href}
                aria-describedby={tier.id}
                className={classNames(
                  tier.featured
                    ? 'bg-white/10 text-white hover:bg-white/20 focus-visible:outline-white'
                    : 'bg-blue-600 text-white shadow-sm hover:bg-blue-500 focus-visible:outline-blue-600',
                  'mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2'
                )}
              >
                {tier.cta}
              </a>
              <ul
                className={classNames(
                  tier.featured ? 'text-gray-300' : 'text-gray-600',
                  'mt-8 space-y-3 text-sm leading-6 xl:mt-10'
                )}
              >
                {tier.features.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckIcon
                      className={classNames(tier.featured ? 'text-white' : 'text-blue-600', 'h-6 w-5 flex-none')}
                      aria-hidden="true"
                    />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
