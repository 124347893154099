import { useEffect, useState } from "react"
import accountService from "../services/accounts.service"
import projectService from "../services/projects.service";
import leadsService from "../services/leads.service"; // Import the leads service
import authenticationService from '../services/authorization-services/auth.service';
import Cookies from 'universal-cookie';
import { useNavigate,} from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { Link } from 'react-router-dom';

  
export default function Home() {
  const [stats, setStats] = useState([]);
  const [userRole, setUserRole] = useState('');
  const navigate = useNavigate();

  const handleViewClick = (path) => {
    navigate(path);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    async function fetchStats() {
      const userProfile = await authenticationService.getUserProfile();
      setUserRole(userProfile.account_type); 
      const cookies = new Cookies();
      cookies.set('account_type', userRole, { path: '/', expires: new Date(Date.now() + (2 * 24 * 60 * 60 * 1000)) });

      let statsArray = [];

      // if (userRole === "referral_partner") {
      //   const leadsResponse = await leadsService.getLeads(); 
      //   statsArray.push({ name: 'Referrals', stat: leadsResponse.data.length.toString(), path: '/referrals' });
      // }

      if (userRole === "sales_partner" || userRole === "referral_partner" || userRole === "admin") {
        const accountsResponse = await accountService.getAccounts();
        const projectsResponse = await projectService.getProjects();
        statsArray.push(
          { name: 'Accounts', stat: accountsResponse.data.length.toString(), path: '/accounts' },
          { name: 'Projects', stat: projectsResponse.data.length.toString(), path: '/projects' },
        );
      }

      setStats(statsArray);
    };

    fetchStats();
  }, [userRole]); 
  
  const pageContent = (
    <>
      <div className="pb-2">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-2">
          <nav className="hidden sm:flex mt-5" aria-label="Breadcrumb">
            <ol className="flex items-center space-x-4">
              <li>
                <div className="flex">
                <Link to="/home" className="text-sm font-medium text-gray-500 hover:text-gray-700">
                  Home
                </Link>
                </div>
              </li>
            </ol>
          </nav>
        </div>   
      </div>

      <main>
        <div className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8 mt-2">
          <div className="rounded-lg bg-white px-5 py-6 shadow-lg border border-gray-100 sm:px-6">
            <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
              <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
                <div className="ml-4 mt-2">
                    <h3 className="text-base font-semibold leading-6 text-gray-900">My Pipeline</h3>
                </div>
              </div>
            </div>
            <div className="flex min-h-full flex-col">
              <div className="px-0 sm:px-0 lg:px-0">  
                <dl className="mt-5 grid grid-cols-1 gap-4 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow ">
                  {stats.map((item) => (
                    <div key={item.name} className="px-4 py-5 sm:p-6">
                      <dt className="text-base font-normal text-gray-900">{item.name}</dt>
                      <dd className="mt-1 flex items-baseline justify-between  ">
                        <div className="flex items-baseline text-2xl font-semibold text-orange-500">
                          {item.stat}
                        </div>
                        <button
                          onClick={() => handleViewClick(item.path)}
                          className="mt-2 inline-flex items-center justify-center rounded-md border border-transparent bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-700 min-w-[140px]"
                        >
                          View {item.name}
                        </button>
                      </dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
          </div>
        </div>

        <div className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8 mt-5 ">
          <div className="rounded-lg bg-white px-5 py-6 shadow-lg border border-gray-100 sm:px-6">
            <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
              <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
                <div className="ml-4 mt-2">
                  <h3 className="text-lg font-semibold leading-6 text-gray-900">Sunin Training</h3>
                </div>
              </div>
            </div>
            <div className="flex min-h-full flex-col items-center justify-center">
              <p className="text-md mt-12 mb-6 max-w-xl">Access training materials on solar energy. Suitable for various learning levels.</p>
                <a href="/sunin-school" className="inline-block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-6 mb-6 rounded focus:outline-none focus:shadow-outline text-center transition-colors duration-300">
                  Visit Training Portal
                </a>
            </div>
          </div>
        </div>

        {/* <div className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8 mt-5">
          <div className="rounded-lg bg-white px-5 py-6 shadow-lg border border-gray-100 sm:px-6">
            <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
              <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
                <div className="ml-4 mt-2">
                  <h3 className="text-base font-semibold leading-6 text-gray-900">Key Performance Indicators</h3>
                </div>
              </div>
            </div>
            <div className="flex min-h-full flex-col items-center justify-center">
              <div className="px-0 sm:px-0 lg:px-0">
                <p className="text-lg mt-12 mb-12">Coming Soon...</p>
              </div>
            </div>
          </div>
        </div> */}

      </main>  
    </>
  );
  return pageContent
}
